import React, { useState } from 'react';
import DefaultContainer from './DefaultContainer';
import { connect } from 'react-redux';
import { ArtworkLibraryProvider } from '../components/artwork-library/context';
import ArtworkLibrary from '../components/artwork-library/ArtworkLibrary';
import { Button } from '@commonsku/styles';

/**
 * This file is for demo/testing for artwork library
 */
const EmptyApp = () => {
  const [artworkOpen, setArtworkOpen] = useState(false);
  const isModal = true;
  const modalControl = isModal ? <Button style={{ width: '200px' }} onClick={() => setArtworkOpen(true)}>Open Library</Button> : null;
  return <DefaultContainer>
    <ArtworkLibraryProvider
      initParents={[
        {
          parent_type: 'PRODUCT',
          parent_id: 'a436a690-16f5-4837-9d9b-9a3a0ee790c7',
        },
        {
          parent_type: 'JOB',
          parent_id: 'dec6ecca-b674-4c6d-9a64-0367ccf69f5a',
        },
        {
          parent_type: 'CLIENT',
          parent_id: 'f8445f9e-7e80-4d64-91ec-c75b689d0d21',
        },
      ]}
      initParent={{
        parent_type: 'CLIENT',
        parent_id: 'f8445f9e-7e80-4d64-91ec-c75b689d0d21',
      }}
      isModal={isModal}
      modalOpen={artworkOpen}
      setModalOpen={setArtworkOpen}
      onFileSelect={(f) => { console.log('selected', f); }}
    >
      {modalControl}
      <ArtworkLibrary />
    </ArtworkLibraryProvider>
  </DefaultContainer>;
};

export default connect(() => { }, () => { })(EmptyApp);
