import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Theme } from '@commonsku/styles';
import { getPopups } from '../selectors';
import MainSection from './MainSection';
import Header from './Header';
import Overlay from '../components/Overlay';

const DefaultContainer = ({ children }) => {
  const headerContainerRef = useRef(null);
  const [topPadding, setTopPadding] = useState(window.innerWidth <= 1023 ? 145 : 120);
  const popups = useSelector(getPopups);

  useEffect(() => {
    if (headerContainerRef.current) {
      setTopPadding(headerContainerRef.current.offsetHeight);
    }
  }, []);

  return <Theme theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }}>
    <Header headerContainerRef={headerContainerRef}/>
    <MainSection popups={popups}>
      <div className="main-content" style={{
        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden',
        marginTop: 0, paddingTop: topPadding,
        display: 'flex', flexDirection: 'column',
      }}>
        {children}
      </div>
    </MainSection>
    <Overlay popups={popups}/>
  </Theme>;
};

export default DefaultContainer;

